import {
  Flex,
  Heading,
  Text,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  IconButton,
  Stat,
  StatLabel,
  StatNumber,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Fade,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Loading } from "../../common/Loading";
import { Wrapper } from "../../common/Wrapper/index";
import { LostNotification } from "../Lost";
import { OwnerInfo } from "../Owner";
import { isMobile } from "react-device-detect";

export const Profile = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showButton, setShowButton] = React.useState(false);
  const [owner, setOwner] = React.useState(false);

  const params: any = useParams();

  const { isLoading, isError, error, data } = useQuery("petInfo", () =>
    fetch(
      `https://bringhome-me2-default-rtdb.firebaseio.com/pets/${params?.petId}/pet.json`
    ).then((res) => res.json())
  );

  React.useLayoutEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setShowButton(true);
      }, 1000);
    }
  }, [isLoading]);

  if (!isLoading && data === null) {
    return (
      <Wrapper>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Sorry!</AlertTitle>
          <AlertDescription>
            No pet found, please check the spelling and try again.
          </AlertDescription>
        </Alert>
      </Wrapper>
    );
  }

  if (isLoading) {
    return (
      <Wrapper
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </Wrapper>
    );
  }

  return (
    <>
      <Box
        h={isMobile ? "calc(100vh - 140px)" : "calc(100vh - 60px)"}
        w="100vw"
        backgroundImage={`https://s3.amazonaws.com/bringhome.me/${params.petId.toLowerCase()}.jpeg`}
        backgroundSize="cover"
        position="absolute"
        backgroundPosition="center"
        zIndex={-1}
      />
      <Wrapper style={{ backgroundColor: "transparent" }}>
        <Flex
          bg="transparent"
          justifyContent="flex-start"
          p="0px 25px"
          flexDir="column"
        >
          <Heading
            lineHeight="100px"
            fontFamily="'Lobster', cursive"
            as="h1"
            size="4xl"
            isTruncated
            color="white"
            textAlign="left"
          >
            {data?.name}
          </Heading>
          <Flex>
            <Text fontSize="lg" color="white" fontWeight="medium">
              {data?.breed}
            </Text>
            <Text
              padding="0px 5px"
              fontSize="lg"
              color="white"
              fontWeight="medium"
            >
              |
            </Text>
            <Text fontSize="lg" color="white" fontWeight="medium">
              {data?.age} Years Old
            </Text>
          </Flex>
          <Text
            textAlign="left"
            fontSize="lg"
            color="white"
            fontWeight="medium"
          >
            {data?.unique}
          </Text>
        </Flex>
        <Fade in={showButton}>
          <IconButton
            aria-label="info"
            icon={<AiOutlineInfoCircle />}
            colorScheme="gray"
            onClick={onOpen}
            position="absolute"
            bottom="20px"
            right="20px"
          />
        </Fade>
        <Drawer isOpen={isOpen} placement="bottom" size="lg" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton colorScheme="whiteAlpha" />
            <DrawerHeader>{data?.name}'s Details</DrawerHeader>

            <DrawerBody>
              <Stat paddingBottom="5">
                <StatLabel>Name</StatLabel>
                <StatNumber>{data?.name}</StatNumber>
              </Stat>
              <Stat paddingBottom="5">
                <StatLabel>Breed</StatLabel>
                <StatNumber>{data?.breed}</StatNumber>
              </Stat>
              <Stat paddingBottom="5">
                <StatLabel>Unique Characteristics</StatLabel>
                <StatNumber>{data?.unique}</StatNumber>
              </Stat>
              <Flex>
                <Stat paddingBottom="5">
                  <StatLabel>Weight</StatLabel>
                  <StatNumber>{data?.weight}</StatNumber>
                </Stat>
                <Stat paddingBottom="5">
                  <StatLabel>Age</StatLabel>
                  <StatNumber>{data?.age}</StatNumber>
                </Stat>
              </Flex>
              {!data.isSafe && (
                <OwnerInfo isSafe={data?.isSafe} setOwner={setOwner} />
              )}
            </DrawerBody>
            <DrawerFooter>
              <Button onClick={onClose} colorScheme="gray">
                Close
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
        {!data.isSafe && (
          <LostNotification
            isSafe={!data?.isSafe}
            name={data?.name}
            owner={owner}
          />
        )}
      </Wrapper>
    </>
  );
};

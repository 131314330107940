import { Alert, AlertIcon, Box, Slide } from "@chakra-ui/react";
import React from "react";
import { AiOutlineAlert } from "react-icons/ai";

export const LostNotification = (props: { isSafe: boolean; name: string; owner: any }) => {
  const [animate, setAnimate] = React.useState(false);

  React.useLayoutEffect(() => {
    if (props.isSafe) {
      setTimeout(() => {
        setAnimate(true);
      }, 1000);
    }
  }, [props.isSafe]);

  if (animate) {
    return (
      <Box
        position="absolute"
        bottom="50px"
        left={0}
        right={0}
        ml="auto"
        mr="auto"
        maxWidth="400px"
      >
        <Slide direction="bottom" in={animate}>
          <Alert
            status="error"
            boxShadow="lg"
            borderRadius="10px 10px 0px 0px"
            variant="solid"
          >
            <Box pr="3">
              <AiOutlineAlert />
            </Box>
            {props.name} is reported missing!
             {/* From owner: {props.owner?.memo} */}
          </Alert>
        </Slide>
      </Box>
    );
  }
  return null;
};

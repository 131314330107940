import { Flex, Spinner, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import React from "react";
import { useQuery } from "react-query";

export const OwnerInfo = (props: {
  isSafe: boolean;
  setOwner(d: any): void;
}) => {
  const { isLoading, isSuccess, error, data } = useQuery("ownerInfo", () =>
    fetch(
      `https://bringhome-me2-default-rtdb.firebaseio.com/pets/lola/owner.json`
    ).then((res) => res.json())
  );

  React.useLayoutEffect(() => {
    if (props.isSafe) {
      props.setOwner(data);
    }
  }, [isLoading]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Flex>
      {!props.isSafe && (
        <>
          <Stat paddingBottom="5">
            <StatLabel>Owner</StatLabel>
            <StatNumber>{data?.ownerName}</StatNumber>
          </Stat>
          <Stat paddingBottom="5">
            <StatLabel>Phone Number</StatLabel>
            <StatNumber>{data?.phone}</StatNumber>
          </Stat>
        </>
      )}
    </Flex>
  );
};

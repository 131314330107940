import {
  Heading,
  Text,
  Image,
  Box,
  Input,
  InputGroup,
  Button,
  Fade,
} from "@chakra-ui/react";
import { Wrapper } from "../../common/Wrapper";
import BRINGHOME_ME_LOGO from "../../assets/BringHomeLogo.svg";
import React from "react";
import { Link } from "react-router-dom";

export const Welcome = () => {
  const [search, setSearch] = React.useState("");
  const [showLegal, setShowLegal] = React.useState(false);

  React.useLayoutEffect(() => {
    setTimeout(() => {
      setShowLegal(true);
    }, 1000);
  }, []);

  return (
    <Wrapper
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDir="column"
      >
        <Image h="100px" src={BRINGHOME_ME_LOGO} />
        <Heading
          lineHeight="100px"
          fontFamily="'Lobster', cursive"
          as="h1"
          size="4xl"
          isTruncated
          color="red.400"
          textAlign="left"
        >
          Bringhome.me
        </Heading>
        <Text
          fontSize="lg"
          color="red.400"
          fontWeight="medium"
          paddingBottom="4"
        >
          Bring Fido Home
        </Text>
        <Box display="flex" flexDir="row">
          <InputGroup>
            <Input
              onChange={(e: any) => {
                setSearch(e.target.value.toLowerCase());
              }}
              colorScheme="red"
              type="text"
              placeholder="Pet name"
            />
          </InputGroup>
          <Link to={`/${search}`}>
            <Button marginLeft="2">Find</Button>
          </Link>
        </Box>
      </Box>
      <Fade in={showLegal}>
        <Text
          left="calc(50vw - 160px)"
          position="fixed"
          bottom="10px"
          fontSize="xs"
          textAlign="center"
          color="gray.300"
        >
          Bringhome.me Copyright 2022. All Rights Reserved.
        </Text>
      </Fade>
    </Wrapper>
  );
};

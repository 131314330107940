import { Box, ScaleFade } from "@chakra-ui/react";
import { isMobile } from 'react-device-detect';

export const Wrapper = (props: {
  children: React.ReactNode;
  backgroundImage?: string;
  style?: any;
}) => {
  return (
    <Box
      h={isMobile ? "calc(100vh - 140px)" : "calc(100vh - 60px)"}
      style={props.style}
      {...props}
    >
      <ScaleFade in>{props.children}</ScaleFade>
    </Box>
  );
};
